import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const PosterityHealthLandingPage = () => (
  <PhysicianLandingPage
    physician="Posterity Health"
    institution="Posterity Health"
    referralCode="POSTERITY"
  />
)

export default PosterityHealthLandingPage
